/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      //toggle search
      $( ".js--libby-search-trigger" ).click(function() {

        $( '.libby-search-overlay-wrapper' ).addClass( "libby-search-overlay-wrapper--is-open" );
        $( '.libby-search-overlay-wrapper' ).find('input').focus();
      });

      $( ".js-libby-close-search-trigger" ).click(function() {
        $( '.libby-search-overlay-wrapper' ).removeClass( "libby-search-overlay-wrapper--is-open" );
      });

      //Create Owl Carousel for Hero
      if ($(".libby_hero_slider").length >= 1) { //check if it exsists
        jQuery('.libby_hero_slider').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          dots: true,
          dotsEach: true,
          responsive: false,
          nav: true,
          autoPlay: false
        });
      }

      if ($(".libby_hero_slider_auto").length >= 1) { //check if it exsists
        jQuery('.libby_hero_slider_auto').owlCarousel({
          items: 1,
          loop: true,
          lazyLoad: true,
          dots: true,
          dotsEach: true,
          responsive: false,
          nav: true,
          autoPlay: true,
          autoPlayTimeout: 1000,
          autoplayHoverPause: true
        });
      }

      //Search
      (function($) {
      var catalogSearchURL = $('.header--searchform').attr('action'), input;
      
        $('.header--searchform').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_site' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
              $(this).closest("form").attr('target', '_blank');
          }
        });
      
        $(".button_catalog").click(".button_catalog", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          //$("#s").val('');
          $('.search-input--libby').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_site").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          //$("#s").val('');
          $('input.search-input--libby').attr('placeholder', 'Search the website');
        });

      })(jQuery);


      //MOBILE search
      (function($) {
      var catalogSearchURL = $('.mobile--searchform').attr('action'), input;
      
        $('.mobile--searchform').on('submit', function(){
          var $selectedRadio = $(this).find('input[name="search_select"]:checked');
          if ( $selectedRadio.attr('id') === 'button_sitem' ) {
            $(this).closest("form").attr('action', '/');
              $(this).closest("form").attr('target', '_self');
          }
          else {
            $(this).closest("form").attr('action', catalogSearchURL);
              $(this).closest("form").attr('target', '_blank');
          }
        });
      
        $(".button_catalogm").click(".button_catalogm", function() {
          console.log('catalog buton clicked');
          $(this).closest("form").attr('action', catalogSearchURL);
          $(this).closest("form").attr('target', '_blank');
          $('.libby-s').attr('placeholder', 'Search the catalog');
        });
        
        $(".button_sitem").click(function() {
        console.log('website buton clicked');
          $(this).closest("form").attr('action', '/');
          $(this).closest("form").attr('target', '_self');
          $('input.libby-s').attr('placeholder', 'Search the website');
        });
        
      })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
